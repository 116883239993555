

import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import '../style/quotecontainer.css'; // Import the custom CSS file

// import logo from './mainlogo.svg'; // Correct path to your logo
import logo from './back.webp'; // Correct path to your logo
import { Link, useHistory } from 'react-router-dom'; // Assuming you use React Router for navigation



const AboutPlatform = () => {



    return (

        <div>
            <h1 style={{ color: 'green', fontSize: '15px' }} className="introText">
                <span> Empowering you with authentic insights into today’s influencers.</span>
                <p>Your trusted space for genuine influencer reviews and recommendations.</p>
            </h1>


        </div>

    )





};

export default AboutPlatform;
