// // import React, { createContext, useContext, useState } from 'react';

// // const UserContext = createContext();

// // export const UserProvider = ({ children }) => {
// //     const [users, setUsers] = useState([]);
// //     const [allFetchedUsers, setAllFetchedUsers] = useState([]);
// //     const [selectedCategory, setSelectedCategory] = useState('');

// //     return (
// //         <UserContext.Provider value={{ users, setUsers, allFetchedUsers, setAllFetchedUsers, selectedCategory, setSelectedCategory }}>
// //             {children}
// //         </UserContext.Provider>
// //     );
// // };

// // export const useUserContext = () => useContext(UserContext);






// import React, { createContext, useContext, useState } from 'react';

// const UserContext = createContext();

// export const UserProvider = ({ children }) => {
//     const [users, setUsers] = useState([]);
//     const [allFetchedUsers, setAllFetchedUsers] = useState([]);
//     const [selectedCategory, setSelectedCategory] = useState('');

//     // Function to reset users and allFetchedUsers
//     const resetContextOnCategoryChange = () => {
//         setUsers([]); // Clear the current users
//         setAllFetchedUsers([]); // Clear the fetched users
//         setSelectedCategory(); // Update the selected category
//     };

//     return (
//         <UserContext.Provider value={{
//             users,
//             setUsers,
//             allFetchedUsers,
//             setAllFetchedUsers,
//             selectedCategory,
//             setSelectedCategory,
//             resetContextOnCategoryChange
//         }}>
//             {children}
//         </UserContext.Provider>
//     );
// };

// export const useUserContext = () => useContext(UserContext);
import React, { createContext, useContext, useState } from 'react';

// Create a context
const UserContext = createContext();

// Create a custom hook to use the context
export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [users, setUsers] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [scrollPosition, setScrollPosition] = useState(0); // New state for scroll position

    return (
        <UserContext.Provider value={{ users, setUsers, selectedCategory, setSelectedCategory, scrollPosition, setScrollPosition }}>
            {children}
        </UserContext.Provider>
    );
};
