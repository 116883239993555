
// import React from 'react';
// import '../style/HowItWorks.css';

// import Header from './Header';

// function HowItWorks() {
//     return (
//         <>
//             <Header />
//             <section className="how-it-works">
//                 <h2 className="section-title">Our Purpose & How This Site Works</h2>
//                 <div className="steps">
//                     <div className="step">
//                         <h3 className="step-title">Step 1: Join the Community</h3>
//                         <ul className="step-list">
//                             <li>Register or sign up to become part of a community committed to authenticity.</li>
//                             <li>Explore a curated list of influencers across various categories.</li>
//                             <li>Help others with real reviews, supporting transparency in the influencer world.</li>
//                         </ul>
//                     </div>
//                     <div className="step">
//                         <h3 className="step-title">Step 2: Share Your Experience</h3>
//                         <ul className="step-list">
//                             <li>Found an influencer worth recommending or warning others about? Rate and review their content quality and overall impact.</li>
//                             <li>Every review counts, from a 5-star endorsement to constructive feedback.</li>
//                             <li>Influencers' profiles showcase aggregated ratings, making it easy for others to make informed choices.</li>
//                         </ul>
//                     </div>
//                     <div className="step">
//                         <h3 className="step-title">Step 3: Discover & Decide</h3>
//                         <ul className="step-list">
//                             <li>Browse influencers, compare ratings, and read real experiences to make informed decisions.</li>
//                             <li>Empower yourself to find the best influencers while encouraging authenticity.</li>
//                             <li>Join us and help build a trustworthy hub in the influencer world!</li>
//                         </ul>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// }

// export default HowItWorks;
import React from 'react';
import '../style/HowItWorks.css';
import Header from './Header';

function HowItWorks() {
    return (
        <>
            <Header />
            <section className="how-it-works">
                <h2 className="section-title">Our Purpose & How This Site Works</h2>
                <div className="how-it-works-content">
                    <div className="intro">
                        <h3>Welcome to Know Your Influencer</h3>
                        <p>
                            Our mission is to bring transparency and trust to the influencer world by providing a community-driven platform for genuine feedback. Discover, rate, and share your experiences with influencers across a variety of domains.
                        </p>
                    </div>
                    <div className="steps">
                        <div className="step">
                            <h3>1. Join the Community</h3>
                            <p>Register to be part of a community dedicated to honest reviews and authentic experiences.</p>
                        </div>
                        <div className="step">
                            <h3>2. Share Your Experience</h3>
                            <p>Leave impactful ratings and feedback on influencers to help others make informed decisions.</p>
                        </div>
                        <div className="step">
                            <h3>3. Discover & Decide</h3>
                            <p>Compare ratings, read reviews, and find the influencers who align with your values.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HowItWorks;
