// import React, { useState } from 'react';


// import '../style/quotecontainer.css';
// import '../style/signup.css';
// import Header from './Header';
// import Footer from './Footer';


// const OurPurpose = () => {
//     return (
//         <>
//             <Header />
//             <div style={{ backgroundColor: '#f9f9f9' }}>

//                 <h1 style={{ color: 'green' }} className="introText">
//                     Empowering you with authentic insights into today’s influencers.
//                 </h1>
//                 <h1 style={{ color: 'green' }} className="introText">
//                     Your trusted space for genuine influencer reviews and recommendations.
//                 </h1>
//                 <div style={{
//                     display: 'flex', justifyContent: 'center',
//                     fontSize: 'medium'
//                 }}></div>
//             </div>
//             <Footer />
//         </>


//     )
// };

// export default OurPurpose;


import React from 'react';
import '../style/quotecontainer.css';
import '../style/signup.css';
import Header from './Header';
import Footer from './Footer';

const OurPurpose = () => {
    return (
        <>
            <Header />
            <div style={{ backgroundColor: '#f9f9f9', padding: '20px' }}>
                <h1 style={{ color: 'green', textAlign: 'center' }} className="introText">
                    Empowering you with authentic insights into today’s influencers.
                </h1>
                <h2 style={{ color: 'green', textAlign: 'center' }} className="introText">
                    Your trusted space for genuine influencer reviews and recommendations.
                </h2>

                {/* Purpose Section */}
                <section style={{ marginTop: '40px', padding: '20px', textAlign: 'center' }}>
                    <h3 style={{ color: '#333' }}>Our Purpose</h3>
                    <p style={{ fontSize: 'medium', color: '#555' }}>
                        Know Your Influencer is dedicated to building a trusted community where users can rate, review,
                        and explore influencers across various domains. Our goal is to provide a platform that empowers
                        individuals with honest feedback, allowing them to make informed choices when it comes to social media influencers.
                    </p>
                </section>

                {/* Mission Section */}
                <section style={{ marginTop: '20px', padding: '20px', textAlign: 'center' }}>
                    <h3 style={{ color: '#333' }}>Our Mission</h3>
                    <p style={{ fontSize: 'medium', color: '#555' }}>
                        To foster a transparent environment where community members can share genuine insights and
                        engage in meaningful discussions. We aim to create a space that promotes fairness, supports
                        authentic reviews, and encourages informed decision-making regarding influencer impact.
                    </p>
                </section>

                {/* Values Section */}
                <section style={{ marginTop: '20px', padding: '20px', textAlign: 'center' }}>
                    <h3 style={{ color: '#333' }}>Our Values</h3>
                    <ul style={{ listStyleType: 'none', padding: 0, color: '#555', fontSize: 'medium' }}>
                        <li><strong>Transparency:</strong> Cultivating a platform that prioritizes open and honest feedback.</li>
                        <li><strong>Community Engagement:</strong> Building a supportive space for users to share their views and insights.</li>
                        <li><strong>Authenticity:</strong> Ensuring ratings and reviews reflect genuine user experiences.</li>
                        <li><strong>Growth:</strong> Creating a scalable, evolving platform with future opportunities for interactive features.</li>
                    </ul>
                </section>

                {/* SignUp/SignIn Prompt */}

            </div>
            <Footer />
        </>
    );
};

export default OurPurpose;
