


// perfectly working                         this just wanted below more code may look better for current thigns. 
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../style/card.css';

const Card = ({
    image,
    productName,
    expertId,
    expertName,
    prodCategory,
    email,
    currentUserId,
    userName,
    rating,
    reviewsCount,
    expertise
}) => {
    return (
        <div className='influencer_list'>
            <div className="card shadow influencer-card">
                <div>
                    <img
                        style={{
                            width: '150px',
                            height: '150px',
                            objectFit: 'cover',
                            borderRadius: '8px'
                        }}
                        src={image || 'placeholder.png'}
                        alt={productName || 'Expert Image'}
                    />
                </div>
                <div className="card-body">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <h6 className="card-title font-weight-bold">{productName}</h6>
                    </div>
                    <div className="card-text">
                        <span style={{ fontSize: '1.5rem' }}>★</span>
                        {Math.round(rating * 10) / 10} [ {reviewsCount} reviews ]
                    </div>
                    <div className="card-text">
                        Expertise: {expertise}
                    </div>
                    <h5 className="card-text">
                        <small className="text-muted">{email}</small>
                    </h5>
                    <Link
                        to={`/ask-questions/${expertId}?currentUserId=${currentUserId}&askedToName=${expertName}&prodCategory=${prodCategory}&userName=${userName}`}
                        className="btn btn-primary btn-sm"
                    >
                        Rate now
                    </Link>
                    <Link
                        to={`/reviews/${expertId}?currentUserId=${currentUserId}&askedToName=${expertName}&prodCategory=${prodCategory}&userName=${userName}`}
                        className="btn btn-primary btn-sm"
                    >
                        All comments
                    </Link>


                </div>
            </div>
        </div>
    );
};

Card.propTypes = {
    image: PropTypes.string,
    productName: PropTypes.string.isRequired,
    expertId: PropTypes.string.isRequired,
    expertName: PropTypes.string.isRequired,
    prodCategory: PropTypes.string,
    email: PropTypes.string,
    currentUserId: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
    rating: PropTypes.number,
    reviewsCount: PropTypes.number,
    expertise: PropTypes.string
};

export default Card;
