


import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../style/comments.css';
import Card from '../component/Card';

const AskQuestions = () => {
    const { expertId } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);


    const userName = queryParams.get('userName');

    const expertName = queryParams.get('askedToName');
    const currentUserId = queryParams.get('currentUserId');

    const [loading, setLoading] = useState(false);
    const [reviews, setReviews] = useState([]);
    const [replyDetails, setReplyDetails] = useState('');
    const [activeReplyBox, setActiveReplyBox] = useState(null);
    const [loadingState, setLoadingState] = useState({});

    const token = localStorage.getItem('token');

    const fetchReviews = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        try {
            const response = await axios.get('/api/allReviews', {
                params: { expertId, currentUserId },
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            setReviews(response.data.questions || []);
        } catch (error) {
            console.error('Error fetching reviews: ', error);
            toast.error('Failed to fetch reviews.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchReviews();
    }, [expertId, currentUserId]);



    const updateLocalComment = (questionId, action, isLike = true) => {



        if (!token) toast.error("Please log in or Sign Up !")
        setReviews(prevReviews =>
            prevReviews.map(review => {
                if (review._id === questionId) {
                    if (action === 'like') {
                        // Toggle like: add if not present, remove if already present
                        const hasLiked = review.likes.includes(currentUserId);
                        return {
                            ...review,
                            likes: hasLiked
                                ? review.likes.filter(id => id !== currentUserId)
                                : [...review.likes, currentUserId],
                            disLikes: review.disLikes.filter(id => id !== currentUserId) // Ensure dislike is removed
                        };
                    } else if (action === 'dislike') {
                        // Toggle dislike: add if not present, remove if already present
                        const hasDisliked = review.disLikes.includes(currentUserId);
                        return {
                            ...review,
                            disLikes: hasDisliked
                                ? review.disLikes.filter(id => id !== currentUserId)
                                : [...review.disLikes, currentUserId],
                            likes: review.likes.filter(id => id !== currentUserId) // Ensure like is removed
                        };
                    } else if (action === 'flag') {
                        if (!review.flag.includes(currentUserId)) {
                            // User hasn't flagged yet, add flag
                            return {
                                ...review,
                                flag: [...(review.flag || []), currentUserId]
                            };
                        } else {
                            // User has already flagged, remove flag
                            return {
                                ...review,
                                flag: review.flag.filter(id => id !== currentUserId)
                            };
                        }
                    }
                }
                return review;
            })
        );
    };



    const handleLike = async (questionId) => {




        const token = localStorage.getItem('token');
        setLoadingState((prev) => ({ ...prev, [questionId]: true }));

        try {
            await axios.post(`/api/comment/${questionId}/like`, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            updateLocalComment(questionId, 'like', true);
        } catch (error) {
            console.error('Error liking comment: ', error);
            toast.error('Failed to like comment.');
        } finally {
            setLoadingState((prev) => ({ ...prev, [questionId]: false }));
        }
    };



    const [flaggedCommment, setflaggedCommend] = useState(0);
    const handleFlag = async (questionId) => {
        const token = localStorage.getItem('token');
        setLoadingState((prev) => ({ ...prev, [questionId]: true }));

        try {
            const flag_response = await axios.post(`/api/comment/${questionId}/flag`, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });


            if (flag_response.data.success && flaggedCommment === 0) {
                setflaggedCommend(1);
                updateLocalComment(questionId, 'flag'); // Update local state for flagging
                toast.success("Comment flagged successfully.", { autoClose: 1500 });
            } else if (flaggedCommment === 1) {

                toast.success("Comment unflagged .", { autoClose: 1500 });
            } else {
                setflaggedCommend(0);
                toast.error("Failed to flag comment.");

            }
        } catch (error) {
            console.error('Error flagging comment: ', error);
            toast.error('Failed to flag comment.');
        } finally {
            setLoadingState((prev) => ({ ...prev, [questionId]: false }));
        }
    };

    const handleDislike = async (questionId) => {


        if (toString(currentUserId) === null) {
            toast.error('Please Log in or sign up!');
            return;
        }

        const token = localStorage.getItem('token');
        setLoadingState((prev) => ({ ...prev, [questionId]: true }));

        try {
            await axios.post(`/api/comment/${questionId}/dislike`, {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            updateLocalComment(questionId, 'dislike', false);
        } catch (error) {
            console.error('Error disliking comment: ', error);
            toast.error('Failed to dislike comment.');
        } finally {
            setLoadingState((prev) => ({ ...prev, [questionId]: false }));
        }
    };

    const handleReplySubmit = async (questionId) => {



        if (toString(currentUserId) === null) {
            toast.error('Please Log in or sign up!');
            return;
        }

        if (!replyDetails.trim()) {
            toast.error('Please enter a reply.');
            return;
        }

        const token = localStorage.getItem('token');

        try {
            const comment_reply = await axios.post(`/api/comment/${questionId}/reply`, {
                questionTitle: reviews.find(review => review._id === questionId)?.questionTitle,
                questionDetails: replyDetails
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            // const reply_return_message = JSON.stringify(comment_reply);
            // console.log("comment reply is " + )
            if (comment_reply.data.msg === "bad") {
                toast.error("Contains inappropriate language,Please rewrite");
                return;
            }

            fetchReviews(); // Refresh reviews to include the new reply
            setReplyDetails('');
            setActiveReplyBox(null);
            toast.success('Reply added successfully!');
        } catch (error) {
            console.error('Error replying to comment: ', error);
            toast.error('Failed to add reply.');
        }
    };

    const toggleReplyBox = (questionId) => {
        setActiveReplyBox(prev => (prev === questionId ? null : questionId));
    };

    const toggleReplies = (questionId) => {


        setReviews(prevReviews =>
            prevReviews.map(review => {
                if (review._id === questionId) {
                    return { ...review, showReplies: !review.showReplies };
                }
                return review;
            })
        );
    };

    return (
        <div className="questions-container">
            {/* <h1>Influencer Name:{expertName}</h1> */}
            <h1>{expertName}</h1>

            {(!token && <h5 style={{ color: 'red' }}>Please Log in / Sign up to like and comment </h5>)}

            {loading ? (
                <p>Loading comments...</p>
            ) : (
                <div className="comments-list">
                    {reviews.length > 0 ? (
                        reviews.map(item => (
                            <div className="comment" key={item._id}>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <h4>Title: {item.questionTitle}</h4>
                                    <button onClick={() => handleFlag(item._id)} disabled={loadingState[item._id]}>
                                        {item.flag.includes(currentUserId) ? '🚩 flagged ' : '🏳️ Flag'} {item.flag.length}
                                    </button>

                                </div>
                                <p>Detail: {item.questionDetails}</p>
                                <p><strong>Comment by:</strong> {item.userName}</p>
                                <div className="comment-actions">
                                    <button onClick={() => handleLike(item._id)} disabled={loadingState[item._id]}>
                                        {item.likes.includes(currentUserId) ? '❤️ Liked' : '🤍 Like'} {item.likes.length}
                                    </button>
                                    <button onClick={() => handleDislike(item._id)} disabled={loadingState[item._id]}>
                                        {item.disLikes.includes(currentUserId) ? '💔 Disliked' : '👎 Dislike'} {item.disLikes.length}
                                    </button>
                                    <button onClick={() => toggleReplyBox(item._id)}>
                                        {activeReplyBox === item._id ? 'Cancel Reply' : 'Reply'}
                                    </button>
                                    <button onClick={() => toggleReplies(item._id)}>
                                        {item.showReplies ? 'Hide Replies' : `Show Replies (${item.replies.length})`}
                                    </button>
                                </div>
                                {activeReplyBox === item._id && (
                                    <div className="reply-box">
                                        <textarea
                                            value={replyDetails}
                                            onChange={(e) => setReplyDetails(e.target.value)}
                                            placeholder="Write your reply here..."
                                        />
                                        <button onClick={() => handleReplySubmit(item._id)}>Submit Reply</button>
                                    </div>
                                )}
                                {item.showReplies && item.replies.length > 0 && (
                                    <div>
                                        {item.replies.map(reply => (
                                            <div className="reply" key={reply._id}>
                                                <strong>{reply.askedByName || 'Anonymous'}:</strong> {reply.questionDetails}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))
                    ) : (
                        <><p>Be first to rate and comment </p>
                            <p> You can go back and rate </p></>


                    )}
                </div>
            )}
        </div>
    );
};

export default AskQuestions;
