



import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../style/searchbox.css';

const SearchBox = ({ onResults }) => {
    const [query, setQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [lastQuery, setLastQuery] = useState(''); // Store the last searched query

    useEffect(() => {
        const fetchResults = async () => {
            if (query.trim() === '') {
                onResults([]); // Clear results only if the query is empty
                setLoading(false);
                return;
            }

            if (query !== lastQuery) { // Only search if the query is different
                setLoading(true);
                setLastQuery(query); // Update lastQuery to the current query

                try {
                    const response = await axios.get(`/api/influencers/search`, {
                        params: { query }
                    });
                    onResults(response.data); // Pass only the filtered results
                } catch (error) {
                    console.error('Error fetching search results', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        const delayDebounceFn = setTimeout(fetchResults, 300); // 300ms debounce

        return () => clearTimeout(delayDebounceFn); // Clear the timeout if query changes
    }, [query, onResults, lastQuery]);

    return (
        <div className={`search-box ${loading ? 'loading' : ''}`}>
            <input
                type="text"
                placeholder="Search by name ..."
                value={query}
                onChange={(e) => setQuery(e.target.value)}
            />
            {loading && <div className="loader"></div>}
        </div>
    );
};

export default SearchBox;
