import React from 'react';
import '../style/userFeedback.css';

const HomeGod = () => {
    const handleFeedbackClick = () => {
        window.open('https://docs.google.com/forms/d/e/1FAIpQLScy7C4Sqvm0orPaOKFM0nssVZnT1wtXba6POcfLiARZlGrNNg/viewform?usp=sf_link', '_blank');  // Replace with your actual form link
    };

    return (
        <div className="home-god-container">
            <div className="main-content">
                {/* Main content of HomeGod component */}
            </div>

            {/* Floating Feedback Button */}
            <div className="floating-feedback" onClick={handleFeedbackClick}>
                <img
                    src="https://cdn-icons-png.freepik.com/512/4704/4704569.png"
                    alt="Google Forms Logo"
                    className="feedback-logo"
                />
                <span>Give Feedback</span>
            </div>
        </div>
    );
};

export default HomeGod;
