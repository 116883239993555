



// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { toast } from 'react-toastify';
// import Header from '../component/Header';
// import Footer from '../component/Footer';
// import '../style/influencerSignUp.css';
// import { useHistory } from 'react-router-dom';
// import imageCompression from 'browser-image-compression';

// const SignUp = () => {
//     const [values, setValues] = useState({
//         name: '',
//         Tell_About_Yourself: '',
//         expertise: '',
//         socialHandles: '',
//         role: 2,
//     });

//     const [selectedCategory, setSelectedCategory] = useState('');
//     const [categories, setCategories] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [pic, setPic] = useState(null);
//     const [picLoading, setPicLoading] = useState(false); // Track image upload status
//     const history = useHistory();

//     useEffect(() => {
//         const fetchCategories = async () => {
//             try {
//                 const { data } = await axios.get('/api/category/all');
//                 setCategories(data.categories);
//                 setLoading(false);
//             } catch (err) {
//                 console.log(err.response.data.error);
//                 toast.error(err.response.data.error);
//                 setLoading(false);
//             }
//         };

//         fetchCategories();
//     }, []);

//     const handleChange = name => (e) => {
//         setValues({ ...values, [name]: e.target.value });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         if (picLoading) {
//             toast.info('Please wait for the image to finish uploading.');
//             return;
//         }

//         try {
//             const { data } = await axios.post('/api/influencerAdd', {
//                 ...values,
//                 category: selectedCategory,
//                 pic,
//             });

//             if (data.success) {
//                 toast.success("Request received! Verification in progress. ");
//                 history.push('/');
//             }
//         } catch (err) {
//             console.log(err.response.data.error);
//             toast.error(err.response.data.error);
//         }
//     };

//     const postDetails = async (pics) => {
//         setPicLoading(true);
//         if (!pics) {
//             toast.warning("Please select an image.");
//             setPicLoading(false);
//             return;
//         }

//         const cloudname = "dx428yl39";
//         const options = {
//             maxSizeMB: 0.5,
//             maxWidthOrHeight: 1024,
//             useWebWorker: true,
//         };

//         try {
//             const compressedPic = await imageCompression(pics, options);

//             const data = new FormData();
//             data.append("file", compressedPic);
//             data.append("upload_preset", "chat-app");
//             data.append("cloud_name", cloudname);

//             const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudname}/image/upload`, {
//                 method: "post",
//                 body: data,
//             });

//             const result = await response.json();
//             setPic(result.url.toString());
//             setPicLoading(false);
//             toast.success("Image uploaded successfully.");
//         } catch (error) {
//             console.log(error);
//             toast.error("Image upload failed.");
//             setPicLoading(false);
//         }
//     };

//     return (
//         <div>
//             <Header />
//             <div style={{ width: '100%' }} className="container custom_signup_container pt-5">
//                 <div className="signup_title_heading">
//                     <div className="signup_title">Add Influencer</div>
//                 </div>
//                 <form className="signup_form" onSubmit={handleSubmit}>
//                     <div className="form-outline mb-4">
//                         <input
//                             onChange={handleChange("name")}
//                             type="text"
//                             id="formName"
//                             className="form-control"
//                             value={values.name}
//                             placeholder="Example :  Cristiano Ronaldo"
//                         />
//                         <label className="form-label" htmlFor="formName">Name</label>
//                     </div>
//                     {/* <div className="form-outline mb-4">
//                         <input
//                             onChange={handleChange("email")}
//                             type="email"
//                             id="formEmail"
//                             className="form-control"
//                             value={values.email}
//                             placeholder=" "
//                         />
//                         <label className="form-label" htmlFor="formEmail">Email</label>
//                     </div> */}
//                     {/* <div className="form-outline mb-4">
//                         <input
//                             onChange={handleChange("phone")}
//                             type="text"
//                             id="formPhone"
//                             className="form-control"
//                             value={values.phone}
//                             placeholder=" "
//                         />
//                         <label className="form-label" htmlFor="formPhone">Phone Number</label>
//                     </div> */}
//                     <div className="form-outline mb-4">
//                         <textarea
//                             onChange={handleChange("Tell_About_Yourself")}
//                             id="formDetails"
//                             className="form-control"
//                             value={values.Tell_About_Yourself}
//                             placeholder="Example : Ronaldo is a Portuguese professional footballer "
//                         />
//                         <label className="form-label" htmlFor="formDetails">Tell About Influencer</label>
//                     </div>
//                     <div className="form-outline mb-4">
//                         <input
//                             onChange={handleChange("expertise")}
//                             type="text"
//                             id="formExpertise"
//                             className="form-control"
//                             value={values.expertise}
//                             placeholder=" example : professional footballer  "
//                         />
//                         <label className="form-label" htmlFor="formExpertise">Expertise</label>
//                     </div>
//                     <div className="form-outline mb-4">

//                         <input
//                             onChange={handleChange("socialHandles")}
//                             type="string"
//                             id="socialHandles"
//                             className="form-control"
//                             value={values.socialHandles}
//                             placeholder="example: Youtube Handle links or Insta, only one link  "
//                         />
//                         <label className="form-label" htmlFor="socialHandles">Social Media Handles</label>
//                     </div>
//                     <div className="form-outline mb-4" id="pic">
//                         <div>Upload Profile Picture <p style={{ color: 'red' }}>only JPEG or PNG</p></div>
//                         <span style={{ color: 'red' }}>Please upload square image only </span>
//                         <input
//                             type="file"
//                             accept="image/*"
//                             onChange={(e) => postDetails(e.target.files[0])}
//                         />
//                         {picLoading && <div>Uploading image...</div>}
//                     </div>
//                     <div className="form-group mb-4">
//                         <label htmlFor="formCategory" className="form-label">Category</label>
//                         <select
//                             onChange={e => setSelectedCategory(e.target.value)}
//                             value={selectedCategory}
//                             className="form-select"
//                             id="formCategory"
//                         >
//                             <option value="">Select Category</option>
//                             {categories.map(category => (
//                                 <option key={category._id} value={category.name}>{category.name}</option>
//                             ))}
//                         </select>
//                     </div>
//                     <button
//                         type="submit"
//                         className="btn btn-primary btn-block mb-4"
//                         disabled={picLoading} // Disable the button if image is still uploading
//                     >
//                         Add
//                     </button>
//                 </form>
//             </div >
//             <Footer />
//         </div >
//     )
// }

// export default SignUp;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Header from '../component/Header';
import Footer from '../component/Footer';
import { useHistory } from 'react-router-dom';
import imageCompression from 'browser-image-compression';

import '../style/influencerSignUp.css'

const SignUp = () => {
    const [values, setValues] = useState({
        name: '',
        Tell_About_Yourself: '',
        expertise: '',
        socialHandles: '',
        role: 2,
    });

    const [selectedCategory, setSelectedCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pic, setPic] = useState(null);
    const [picLoading, setPicLoading] = useState(false); // Track image upload status
    const history = useHistory();

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const { data } = await axios.get('/api/category/all');
                setCategories(data.categories);
                setLoading(false);
            } catch (err) {
                console.log(err.response.data.error);
                toast.error(err.response.data.error);
                setLoading(false);
            }
        };

        fetchCategories();
    }, []);

    const handleChange = name => (e) => {
        setValues({ ...values, [name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (picLoading) {
            toast.info('Please wait for the image to finish uploading.');
            return;
        }

        try {
            const { data } = await axios.post('/api/influencerAdd', {
                ...values,
                category: selectedCategory,
                pic,
            });

            if (data.success) {
                toast.success("Request received! Verification in progress.");
                history.push('/');
            }
        } catch (err) {
            console.log(err.response.data.error);
            toast.error(err.response.data.error);
        }
    };

    const postDetails = async (pics) => {
        setPicLoading(true);
        if (!pics) {
            toast.warning("Please select an image.");
            setPicLoading(false);
            return;
        }

        const cloudname = "dx428yl39";
        const options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1024,
            useWebWorker: true,
        };

        try {
            const compressedPic = await imageCompression(pics, options);

            const data = new FormData();
            data.append("file", compressedPic);
            data.append("upload_preset", "chat-app");
            data.append("cloud_name", cloudname);

            const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudname}/image/upload`, {
                method: "post",
                body: data,
            });

            const result = await response.json();
            setPic(result.url.toString());
            setPicLoading(false);
            toast.success("Image uploaded successfully.");
        } catch (error) {
            console.log(error);
            toast.error("Image upload failed.");
            setPicLoading(false);
        }
    };

    return (
        <div >
            <Header />
            <div className="form-container">
                <div className="form-title">
                    <h1 style={{ color: 'blue' }}>Add Influencer</h1>
                </div>
                <form className="form" onSubmit={handleSubmit}>
                    <div className="input-field">
                        <input
                            onChange={handleChange("name")}
                            type="text"
                            id="formName"
                            value={values.name}
                            placeholder="e.g., Cristiano Ronaldo"
                        />
                        <label htmlFor="formName">Name</label>
                    </div>

                    <div className="input-field">
                        <textarea
                            onChange={handleChange("Tell_About_Yourself")}
                            id="formDetails"
                            value={values.Tell_About_Yourself}
                            placeholder="e.g., Ronaldo is a professional footballer"
                        />
                        <label htmlFor="formDetails">Tell About Influencer</label>
                    </div>

                    <div className="input-field">
                        <input
                            onChange={handleChange("expertise")}
                            type="text"
                            id="formExpertise"
                            value={values.expertise}
                            placeholder="e.g., Professional Footballer"
                        />
                        <label htmlFor="formExpertise">Expertise</label>
                    </div>

                    <div className="input-field">
                        <input
                            onChange={handleChange("socialHandles")}
                            type="text"
                            id="socialHandles"
                            value={values.socialHandles}
                            placeholder="e.g., YouTube Handle or Instagram"
                        />
                        <label htmlFor="socialHandles">Social Media Handles</label>
                    </div>

                    <div className="file-upload-section">
                        <label>Upload Profile Picture</label>
                        <p className="note">Only JPEG or PNG, square image preferred.</p>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) => postDetails(e.target.files[0])}
                        />
                        {picLoading && <div className="loading-text">Uploading image...</div>}
                    </div>

                    <div className="category-select">
                        <label htmlFor="formCategory">Category</label>
                        <select
                            onChange={e => setSelectedCategory(e.target.value)}
                            value={selectedCategory}
                            id="formCategory"
                        >
                            <option value="">Select Category</option>
                            {categories.map(category => (
                                <option key={category._id} value={category.name}>{category.name}</option>
                            ))}
                        </select>
                    </div>

                    <button
                        type="submit"
                        className="submit-button"
                        disabled={picLoading} // Disable if image is still uploading
                        style={{ display: 'flex', justifyContent: 'center' }}
                    >
                        Add Influencer
                    </button>
                </form>
            </div>
            <Footer />
        </div>
    );
};

export default SignUp;
