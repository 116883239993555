


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../style/userDashboard.css'; // Import your CSS file for UserDashboard styling

import Header from '../../component/Header';
import Footer from '../../component/Footer';

const UserDashboard = () => {
    const [profile, setProfile] = useState({});
    const [myQuestions, setMyQuestions] = useState([]);

    const [answersToMyQuestions, setAnswersToMyQuestions] = useState([]);
    const [questionsForExpert, setQuestionsForExpert] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const questionsPerPage = 10; // Number of questions per page

    useEffect(() => {
        fetchProfile();
    }, []);





    useEffect(() => {
        if (profile._id) {
            // fetchMyQuestions();
            // fetchAnswersToMyQuestions();
            if (profile.role === 2) {
                // fetchQuestionsForExpert();
            }
        }
    }, [profile]);

    const fetchProfile = async () => {
        try {
            const response = await axios.get('/api/getme');
            setProfile(response.data.user);
        } catch (error) {
            toast.error('Failed to fetch profile.');
        }
    };

    // const fetchMyQuestions = async () => {
    //     try {
    //         const response = await axios.get('/api/my-questions');
    //         setMyQuestions(response.data.questions);
    //         console.log("expert name is " + response.data.questions[0].askedTo);
    //     } catch (error) {
    //         // toast.error('Failed to fetch my questions. or There is no question');
    //         toast.success('There is no question asked by you ');
    //         toast.success('Ask your Questions with experts today ');


    //     }
    // };

    // const fetchAnswersToMyQuestions = async () => {
    //     try {
    //         const response = await axios.get('/api/answers-to-my-questions');
    //         setAnswersToMyQuestions(response.data.questions);
    //     } catch (error) {
    //         toast.error('Failed to fetch answers to my questions.');
    //     }
    // };

    // const fetchQuestionsForExpert = async () => {
    //     try {
    //         const response = await axios.get('/api/questions-for-expert');
    //         setQuestionsForExpert(response.data.questions);
    //     } catch (error) {
    //         toast.error('Failed to fetch questions for expert.');
    //     }
    // };

    // const handleAnswerQuestion = async (questionId, answer) => {
    //     try {
    //         await axios.post('/api/answer-question', { questionId, answer });
    //         fetchQuestionsForExpert(); // Refresh questions after submitting
    //         toast.success('Answer submitted successfully!');
    //     } catch (error) {
    //         toast.error('Failed to answer question.');
    //     }
    // };

    // const handleRemoveQuestion = async (questionId) => {
    //     try {
    //         await axios.post('/api/remove-question', { questionId });
    //         fetchMyQuestions();
    //         toast.success('question removed successfully!');
    //     } catch (error) {
    //         toast.error('Failed to remove question.');
    //     }
    // };

    // Calculate pagination range
    // const indexOfLastQuestion = currentPage * questionsPerPage;
    // const indexOfFirstQuestion = indexOfLastQuestion - questionsPerPage;
    // const currentQuestions = myQuestions.slice(indexOfFirstQuestion, indexOfLastQuestion);

    // Function to handle page change
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            <Header />
            <div className="container user-dashboard">
                {/* <h2>User Dashboard</h2> */}
                <div style={{
                    display: 'flex', justifyContent: 'center'
                }}>

                    <h2>Profile</h2>
                </div>

                <div className="card mb-3">
                    <div className="card-header">
                        <h4>User Information</h4>
                    </div>
                    <div className="card-body">

                        <li >Your Name: {profile.name}</li>
                        <li >Email: {profile.email}</li>
                        <li >Role: {profile.role === 1 ? 'Admin' : (profile.role === 2 ? 'Expert' : 'Registered User')}</li>
                        <li >Joined at: {new Date(profile.createdAt).toLocaleDateString()}</li>
                        {/* <li >Details: {profile.details}</li> */}


                    </div>
                </div>


            </div>
            <Footer />
        </>
    );
};

export default UserDashboard;

